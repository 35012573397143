.p-contacts
  .container.page-content
    text-align: center

  h1
    +lte_ipad
      margin-bottom: rem(32px)

    +gte_laptop
      margin-bottom: 56px

  ul.contact-list
    display: inline-block
    margin-bottom: rem(20px)
    text-align: left

    li
      +padding-left_ltr(rem4(50px))
      color: var(--primary-color)
      line-height: rem4(26px)
      margin-bottom: rem4(16px)
      position: relative

      &:before
        background-repeat: no-repeat
        background-size: contain
        content: ''
        height: rem(26px)
        +left_ltr(0)
        position: absolute
        width: rem(26px)

      &.phone
        &:before
          background-image: url('../images/pages/contacts/phone.svg')

      &.email
        &:before
          background-image: url('../images/pages/contacts/email.svg')

      &.address
        color: var(--text-base)

        &:before
          background-image: url('../images/pages/contacts/address.svg')

      &.work-time
        display: flex

        &:before
          background-image: url('../images/pages/contacts/work_time.svg')

        .note
          color: var(--text-base)

  article.map_storages
    margin-bottom: 0

    .container-top
      display: none

    .selfstorage-outer
      display: none

  .info
    position: relative

    +gte_laptop
      min-height: 380px

    h2
      font-size: 24px
      line-height: 28px
      margin-bottom: 32px

      +lte_ipad
        display: none
